//~~	color variables  ~~~~~~~~~~~~~~~~~~~~~~~~~//
$color-contrast-dark:   $gray-900;
$color-contrast-light:  $cream-300;
////																							



//~~	theme color variables  ~~~~~~~~~~~~~~~~~~~//
$primary:		$purple;
$secondary:	$red;
$danger:		$orange;
$light:			shade-color($cream-300, 5%);
$dark:			$gray-900;

$primary-text:            $purple-600;
$secondary-text:          $red-600;
$danger-text:             $orange-600;

$primary-bg-subtle:       $purple-100;
$secondary-bg-subtle:     $red-100;
$danger-bg-subtle:        $orange-100;

$primary-border-subtle:   $purple-200;
$secondary-border-subtle: $red-200;
$danger-border-subtle:    $orange-200;
////																							



//~~	body variables  ~~~~~~~~~~~~~~~~~~~~~~~~~~//
$body-color:	        $gray-900;
$body-bg:			        $cream-300;

$component-active-bg: $primary;
////																							



//~~	anchor variables  ~~~~~~~~~~~~~~~~~~~~~~~~//
$link-color:						$dark;
$link-decoration:				null;
$link-hover-decoration:	underline;

$link-hover-color:			$purple-500;

//-		icon links
$icon-link-gap:								.375rem !default;
$icon-link-underline-offset:	.25em !default;
$icon-link-icon-size:         1em !default;
$icon-link-icon-transition:   .2s ease-in-out transform !default;
$icon-link-icon-transform:    translate3d(.25em, 0, 0) !default;
////																							



//~~	font variables  ~~~~~~~~~~~~~~~~~~~~~~~~~~//
$font-weight-base:	$font-weight-light;

$h1-font-size:			$font-size-base * 3;
$h2-font-size:			$font-size-base * 2.5;
$h3-font-size:			$font-size-base * 2;
////																							



//~~	headings variables  ~~~~~~~~~~~~~~~~~~~~~~//
$headings-font-family:	$font-family-serif;
$headings-font-weight:	$font-weight-normal;
////																							



//~~	display headings variables  ~~~~~~~~~~~~~~//
$display-font-family:	$font-family-serif;
$display-font-weight:	$font-weight-normal;
////																							



//~~	focus ring variables  ~~~~~~~~~~~~~~~~~~~~//
$focus-ring-color:      rgba($primary, $focus-ring-opacity);
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;
////																							



//~~	input button variables  ~~~~~~~~~~~~~~~~~~//
$input-btn-font-family:       $font-family-serif;
$input-btn-font-size:         $h4-font-size;
$input-btn-focus-color:				$focus-ring-color;
$input-btn-focus-box-shadow:	$focus-ring-box-shadow;
////																							



//~~	button variables  ~~~~~~~~~~~~~~~~~~~~~~~~//
$btn-font-family:				$input-btn-font-family;
$btn-font-size:					$input-btn-font-size;

$btn-border-radius:			$border-radius-pill;
$btn-border-radius-sm:	$border-radius-pill;
$btn-border-radius-lg:	$border-radius-pill;
////																							



//~~	toggle button variables  ~~~~~~~~~~~~~~~~~//
$btn-toggle-icon-color:         $dark;
$btn-toggle-icon-active-color:  $dark;

$btn-toggle-icon:  							url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>");
$btn-toggle-active-icon:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
////																							



//~~	chip variables  ~~~~~~~~~~~~~~~~~~~~~~~~~~//
$chip-padding-y:						.5rem !default;
$chip-padding-x:						0.75rem !default;
$chip-font-family:					$font-family-sans-serif !default;
$chip-font-size:						0.8rem !default;
$chip-font-weight:					$font-weight-light !default;
$chip-line-height:					1 !default;
$chip-white-space:					break-spaces !default; // Set to `null` to allow text wrapping

$chip-padding-y-sm:					0.15rem !default;
$chip-padding-x-sm:					0.4rem !default;
$chip-font-size-sm:					$chip-font-size !default;
$chip-font-weight-sm:				$chip-font-weight !default;

$chip-padding-y-lg:					1.5rem !default;
$chip-padding-x-lg:					3rem !default;
$chip-font-size-lg:					$input-btn-font-size-lg !default;
$chip-font-weight-lg:				$font-weight-bold !default;

$chip-border-width:					1px !default;

$chip-disabled-opacity:			0.65 !default;
$chip-link-disabled-color:	$gray-600 !default;

$chip-link-color:						var(--#{$prefix}link-color) !default;

$chip-border-radius:				$border-radius-pill !default;
$chip-border-radius-sm:			$border-radius-pill !default;
$chip-border-radius-lg:			$border-radius-lg !default;
////																							



//~~	baseline-rule variables  ~~~~~~~~~~~~~~~~~//
$baseline-rule-border-width:	1px !default;
$baseline-rule-border-color:	$body-color !default;
////																							



//~~	nav variables  ~~~~~~~~~~~~~~~~~~~~~~~~~~~//
$nav-link-color:				$gray-900;
$nav-link-active-color:	$primary !default;
////																							



//~~	navbar variables  ~~~~~~~~~~~~~~~~~~~~~~~~//
$navbar-light-color:							$gray-900;
$navbar-light-hover-color:				rgba($gray-900, 0.7);
$navbar-light-brand-color:				$gray-900;
$navbar-light-brand-hover-color:	rgba($gray-900, 0.7);
////																							



//~~	form variables  ~~~~~~~~~~~~~~~~~~~~~~~~~~//
//-		form input
$input-padding-y:     				 			0.25rem;
$input-padding-x:      							0.5rem;
$input-font-size:   				   			$font-size-sm;

$input-bg:													transparent;
$input-border-color:								$dark;
$input-focus-bg:										$input-bg;

$input-border-radius:								$border-radius-pill;
$input-border-radius-sm:						$border-radius-pill;
$input-border-radius-lg:          	$border-radius-pill;

$form-textarea-border-radius:				$border-radius-2xl;
$form-textarea-border-radius-sm:		$border-radius-2xl;
$form-textarea-border-radius-lg:		$border-radius-2xl;

$input-focus-border-color:					$dark;
$input-focus-box-shadow:						$input-btn-focus-box-shadow;

//-   form check
$form-check-input-focus-border:     $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-focus-box-shadow;

//-   form switch
$form-switch-focus-color: 					$input-focus-border-color;
$form-switch-focus-bg-image:				url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

//-		form select
$form-select-bg:										$input-bg;
$form-select-border-color:					$input-border-color;

$form-select-border-radius:					$input-border-radius;
$form-select-border-radius-sm:			$input-border-radius-sm;
$form-select-border-radius-lg:			$input-border-radius-lg;

$form-select-focus-border-color:		$input-focus-border-color;
$form-select-focus-box-shadow:			0 0 0 $form-select-focus-width $input-btn-focus-color;

//-		form range
$form-range-thumb-focus-box-shadow:	0 0 0 1px $body-bg, $input-focus-box-shadow !default;

//-		form floating labels
$form-floating-height:							add(2.5rem, $input-height-border);
// $form-floating-line-height:             1.25;
$form-floating-padding-x:               1rem;
$form-floating-padding-y:               0.5rem;
$form-floating-input-padding-t:         0.625rem;
$form-floating-input-padding-b:         0rem;
// $form-floating-label-height:            1.5em;
// $form-floating-label-opacity:           .65;
// $form-floating-label-transform:         scale(.85) translateY(-.5rem) translateX(.15rem);
// $form-floating-label-disabled-color:    $gray-600;
// $form-floating-transition:              opacity .1s ease-in-out, transform .1s ease-in-out;
////																							
