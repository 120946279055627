.nav.flex-column .nav-link {
	position: relative;
	transition: $transition;

	&::before {
		content: '';
		background-color: var(--#{$prefix}primary);
		height: 0.5rem;
		width: 0.5rem;
		transform: rotate(45deg);
		position: absolute;
		left: 0;
		top: calc(50% - 0.2rem);
		opacity: 0;
		transition: $transition;
	}

	&.active {
		--#{$prefix}nav-tabs-link-active-color: #{$nav-link-active-color};
	
		color: var(--#{$prefix}nav-link-active-color);
		text-decoration: underline;
		text-decoration-thickness: 1px;
		text-underline-offset: 0.15em;
	
		&::before { opacity: 1; }
	}
}

.navbar {
	position: fixed;
	width: 100%;
	border-bottom: 1px solid rgba($dark, 0);
	backdrop-filter: none;
	z-index: 999;
	transition: $transition;

	&.scroll {
		border-bottom-color: $dark;
		backdrop-filter: blur(7px);
	}

	.nav-link {
		display: flex;
		flex-direction: column;
	
		span {
			line-height: 1.1;
	
			&:first-child {
				text-transform: uppercase;
				font-size: $font-size-lg;
			}
	
			&:last-child {
				font-family: $font-family-serif;
			}
		}
	}
}

.navbar-toggler {
	border: none;
	padding: revert;
}

.navbar-toggler:focus, .btn-close:focus {
	box-shadow: $focus-ring-box-shadow;
}

.offcanvas {
	height: 100vh;
}



//~~	responsive	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
// X-Small devices (portrait phones, less than 576px)
@media screen and (max-width: 575px) {}

// Small devices (landscape phones, 576px and up)
@media screen and (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media screen and (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media screen and (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media screen and (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media screen and (min-width: 1400px) {}
////																							