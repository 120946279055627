$transition:	all ease-in-out 0.15s;

//*   gray color variables
$gray-100: #F9F9F9;
$gray-200: #E0DFDE;
$gray-300: #C7C5C4;
$gray-400: #AEABAA;
$gray-500: #949190;
$gray-600: #7B7776;
$gray-700: #625D5B;
$gray-800: #494341;
$gray-900: #302927;



//*   color variables
$purple:  #5B3A73;
$red:     #FE5F55;
$cream:   #F6F4EF;

$cream-100: #FDFDFC;
$cream-200: #FAF8F5;
$cream-300: $cream;
$cream-400: #E6E3DF;
$cream-500: #D6D2CF;
$cream-600: #C6C2BF;
$cream-700: #B5B1AE;
$cream-800: #A5A09E;
$cream-900: #958F8E;



//*		font variables
$font-family-sans-serif:				zeitung-micro, sans-serif;
$font-family-serif:							swear-display, serif;
$font-family-serif-decoration:	swear-display-cilati, sans-serif;
$font-family-monospace:					zeitung-mono, monospace;

$font-size-root:								20px;

$font-weight-lighter:           200;
$font-weight-bold:							600;
$font-weight-bolder:            700;

//*   headings variables
$headings-font-weight:  400;



//*		display headings variables
$display-font-sizes: (
	1: 9.75rem,
	2: 8.5rem,
	3: 7.25rem,
	4: 6rem,
	5: 4.75rem,
	6: 3.5rem
);



//*   input btn variables
$input-btn-padding-y:     0.25rem;
$input-btn-padding-x:     1rem;
$input-btn-line-height:	  1.2;

$input-btn-padding-y-sm:  0.25rem;
$input-btn-padding-x-sm:  0.75rem;

$input-btn-border-width:  2px;