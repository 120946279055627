// stylelint-disable function-name-case

$all-colors: map-merge-multiple($purples, $reds, $creams, $grays);

// All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251
@each $color, $value in $all-colors {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color} {
    color: color-contrast($value) if($enable-important-utilities, !important, null);
    background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
  }
}