.card-portfolio {
	align-items: end;

	&:not(:last-child) {
		margin-bottom: 5rem;
	}

	&.portfolio-right { flex-direction: row-reverse; }

	.image-container {
		width: 100%;
		border-radius: $border-radius-2xl;
		aspect-ratio: 4 / 3;
		overflow: hidden;
		filter: saturate(0) sepia(0.1) brightness(0.9);
		transition: $transition;

		img {
			width: 100%;
			object-fit: cover;
			object-position: center;
			transform-origin: center center;
			transition: transform ease-in-out 0.2s;
		}
	}

	&:hover {
		.image-container {
			filter: saturate(1);
			
			img { transform: scale(1.1); }
		}
	}
}