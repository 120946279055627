//~~	base styles  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
.chip {
  //    scss-docs-start chip-css-vars
  --#{$prefix}chip-padding-x: #{$chip-padding-x};
  --#{$prefix}chip-padding-y: #{$chip-padding-y};
  --#{$prefix}chip-font-family: #{$chip-font-family};
  --#{$prefix}chip-font-size: #{$chip-font-size};
  // @include rfs($chip-font-size, --#{$prefix}chip-font-size);
  --#{$prefix}chip-font-weight: #{$chip-font-weight};
  --#{$prefix}chip-line-height: #{$chip-line-height};
  --#{$prefix}chip-color: #{$body-color};
  --#{$prefix}chip-bg: transparent;
  --#{$prefix}chip-border-width: #{$chip-border-width};
  --#{$prefix}chip-border-color: transparent;
  --#{$prefix}chip-border-radius: #{$chip-border-radius};
  --#{$prefix}chip-disabled-opacity: #{$chip-disabled-opacity};
  //    scss-docs-end chip-css-vars

  display: inline-block;
  padding: calc(var(--#{$prefix}chip-padding-y) * 0.8) var(--#{$prefix}chip-padding-x) var(--#{$prefix}chip-padding-y);
  font-family: var(--#{$prefix}chip-font-family);
  font-size: var(--#{$prefix}chip-font-size);
  // @include font-size(var(--#{$prefix}chip-font-size));
  font-weight: var(--#{$prefix}chip-font-weight);
  line-height: var(--#{$prefix}chip-line-height);
  color: var(--#{$prefix}chip-color);
  text-align: left;
  text-decoration: if($link-decoration == none, null, none);
	text-transform: none;
  white-space: $chip-white-space;
  vertical-align: middle;
  user-select: none;
  border: var(--#{$prefix}chip-border-width) solid var(--#{$prefix}chip-border-color);
  @include border-radius(var(--#{$prefix}chip-border-radius));
  @include gradient-bg(var(--#{$prefix}chip-bg));

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--#{$prefix}chip-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}chip-disabled-bg);
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}chip-disabled-border-color);
    opacity: var(--#{$prefix}chip-disabled-opacity);
  }
}
////																							



//~~	alternate chips  ~~~~~~~~~~~~~~~~~~~~~~~~~//
//    scss-docs-start chip-variant-loops
@each $color, $value in $theme-colors {
  .chip-#{$color} {
    @include chip-variant($value, $value);
  }
  .chip-#{$color}-light {
    @include chip-variant(
      tint-color($value, 60%),
      tint-color($value, 60%),
      $value
    );
  }
  .chip-#{$color}-dark {
    @include chip-variant(
      shade-color($value, 10%),
      shade-color($value, 10%)
    )
  }
}

@each $color, $value in $theme-colors {
  .chip-outline-#{$color} {
    @include chip-outline-variant($value);
  }
}
//    scss-docs-end chip-variant-loops
////																							




//~~	link chips	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
//    Make a chip look and behave like a link
.chip-link {
  --#{$prefix}chip-font-weight: #{$font-weight-normal};
  --#{$prefix}chip-color: #{$chip-link-color};
  --#{$prefix}chip-bg: transparent;
  --#{$prefix}chip-border-color: transparent;
  --#{$prefix}chip-disabled-color: #{$chip-link-disabled-color};
  --#{$prefix}chip-disabled-border-color: transparent;

  text-decoration: $link-decoration;
  @if $enable-gradients {
    background-image: none;
  }
}
////																							




//~~	chip sizes	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
.chip-lg {
  @include chip-size(
    calc($chip-padding-y-lg * 0.7),
    calc($chip-padding-x-lg * 0.7),
    $chip-font-size,
    $chip-font-weight-lg,
    $chip-border-radius-lg
  );
}

.chip-sm {
  @include chip-size(
    $chip-padding-y-sm,
    $chip-padding-x-sm,
    $chip-font-size-sm,
    $chip-font-weight-sm,
    $chip-border-radius-sm
  ); 
}
////																							   



//~~	responsive	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
// X-Small devices (portrait phones, less than 576px)
@media screen and (max-width: 575px) {}

// Small devices (landscape phones, 576px and up)
@media screen and (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media screen and (min-width: 768px) {
  .chip {
    --#{$prefix}chip-font-size: #{$chip-font-size};
  }
  .chip-lg {
    --#{$prefix}chip-font-size: #{$chip-font-size-lg};
    --#{$prefix}chip-padding-x: #{$chip-padding-x-lg};
    --#{$prefix}chip-padding-y: #{$chip-padding-y-lg};
  }
}

// Large devices (desktops, 992px and up)
@media screen and (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media screen and (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media screen and (min-width: 1400px) {}
////																							