#Background {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -100;
}

.bg-noise {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: url('assets/noise.png');
	z-index: 1;
	opacity: 0.6;
	mix-blend-mode: hard-light;
	animation: noise 0.09s infinite;
}

.bg-circles {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	filter: blur(50px);
}

.bg-circles.index { position: fixed; }

[class*="bg-circle"]:not(.bg-circles) {
	position: absolute;

	&[class$="_1"] {
		inset: -16vh auto auto 16vw;
		width: 35vw;
		height: 35vh;
		
		border-radius: 20%;
		background-color: $purple-500;
		animation: bg-circle-1 20s linear 0s infinite;
	}

	&[class$="_2"] {
		inset: auto auto 20vh -20vw;
		width: 30vw;
		height: 30vh;
		
		border-radius: 100%;
		background-color: $purple-500;
		opacity: 0.7;
		animation: bg-circle-2 25s linear 0s infinite;
	}

	&[class$="_3"] {
		inset: -2vh auto auto 52vw;
		width: 60vw;
		height: 60vh;

		border-radius: 100%;
		background-color: $cream-100;
		animation: bg-circle-3 16s linear 0s infinite;
	}

	&[class$="_4"] {
		inset: -20vh -10vw auto auto;
		width: 50vw;
		height: 50vh;

		border-radius: 100%;
		background-color: $red-500;
		opacity: 0.8;
		animation: bg-circle-4 14s linear 0s infinite;
	}

	&[class$="_5"] {
		inset: 25vh auto auto 30vw;
		width: 30vw;
		height: 30vh;

		border-radius: 20%;
		background-color: $purple-500;
		animation: bg-circle-5 25s linear 0s infinite;
	}

	&[class$="_6"] {
		inset: 35vh -16vw auto auto;
		width: 40vw;
		height: 40vw;

		border-radius: 100%;
		background-color: $red-500;
		animation: bg-circle-6 30s linear 0s infinite;
	}

	&[class$="_7"] {
		inset: auto -8vw -16vh auto;
		width: 40vw;
		height: 40vh;

		border-radius: 100%;
		opacity: 0.8;
		animation: bg-circle-2 30s linear 0s infinite;
	}

	&[class$="_8"] {
		inset: auto auto -10vh -10vw;
		width: 30vw;
		height: 30vh;

		border-radius: 20%;
		opacity: 0.8;
		animation: bg-circle-4 25s linear 0s infinite;
	}

	&[class$="_9"] {
		inset: auto -2vh 0 30vw;
		width: 35vw;
		height: 35vh;

		border-radius: 100%;
		background-color: $cream-100;
		animation: bg-circle-3 16s linear 0s infinite;
	}
}



//~~	responsive	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
// X-Small devices (portrait phones, less than 576px)
@media screen and (max-width: 575px) {}

// Small devices (landscape phones, 576px and up)
@media screen and (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media screen and (min-width: 768px) {
	.bg-circles { filter: blur(100px) }
}

// Large devices (desktops, 992px and up)
@media screen and (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)       
@media screen and (min-width: 1200px) {
	.bg-circles { filter: blur(150px) }
}

// XX-Large devices (larger desktops, 1400px and up)
@media screen and (min-width: 1400px) {}

////																							



//~~	animations	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
//		background noise
@keyframes noise {
	0%		{ background-position:  0%	 0%;	}
	10%		{ background-position: -5%	-10%;	}
	20%		{ background-position: -15%	-5%;	}
	30%		{ background-position: -7%	-25%;	}
	40%		{ background-position: -20%	-25%;	}
	50%		{ background-position: -25%	 10%;	}
	60%		{ background-position: -15% -5%;	}
	70%		{ background-position:  0%	 15%;	}
	80%		{ background-position:  25%	 35%;	}
	90%		{ background-position: -10%	 10%;	}
	100%	{ background-position:  0%	 0%;	}
}

//		background circles
@keyframes bg-circle-1 {
	0% {
		transform: scale(2, 2);
	}
	25%,
	50% {
		transform: scale(1, 1);
	}
	75%,
	100% {
		transform: scale(2, 2);
	}
}

@keyframes bg-circle-2 {
	0% {
		transform: scale(1, 1);
	}
	25%,
	50%,
	75% {
		transform: scale(2, 2);
	}
	100% {
		transform: scale(1, 1);
	}
}

@keyframes bg-circle-3 {
	0% {
		transform: scale(1, 1);
		opacity: 0;
	}
	25%,
	50%,
	75% {
		transform: scale(2, 2);
		opacity: 1;
	}
	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}

@keyframes bg-circle-4 {
	0%,
	25% {
		transform: scale(2, 2);
	}
	50%,
	75% {
		transform: scale(1, 1);
	}
	100% {
		transform: scale(2, 2);
	}
}

@keyframes bg-circle-5 {
	0% {
		transform: scale(2, 2);
		opacity: 0.8;
	}
	25%,
	50% {
		transform: scale(1, 1);
		opacity: 0;
	}
	75%,
	100% {
		transform: scale(2, 2);
		opacity: 0.8;
	}
}

@keyframes bg-circle-6 {
	0%,
	25% {
		transform: scale(1, 1);
		opacity: 1;
	}
	50%,
	75% {
		transform: scale(2, 2);
		opacity: 0.2;
	}
	100% {
		transform: scale(1, 1);
		opacity: 1;
	}
}
////																							