//~~	utility classes  ~~~~~~~~~~~~~~~~~~~~~~~~~//
img { max-width: 100%; }
.large { font-size: $font-size-lg !important; }
.xsmall { font-size: 0.75em !important; }
.xxsmall { font-size: 0.6em !important;}
.p {
	font-family:	$font-family-sans-serif !important;
	font-size: 		$font-size-base !important;
	font-weight:	$font-weight-base !important;
	line-height:	$line-height-base !important;
}

.ff-sans { font-family: $font-family-sans-serif !important; }
.ff-serif-deco { font-family: $font-family-serif-decoration !important; }
////																							



//~~	overrides  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
::selection {
	color: $cream-300;
	background-color: $secondary;
}

#root {
	position: relative;
	// overflow: hidden;
}

.a > i {
	font-style:inherit !important;
	font-family: $font-family-serif-decoration !important;
}

.icon-link {
	text-decoration-color: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-opacity, 1));
}
////																							


//~~	lists  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
ol, ul { padding-left: 1rem; }

ul:not(.nav):not(.navbar-nav) li {
	list-style: none;
	position: relative;

	&::before {
		content: '\2022';
		position: absolute;
		left: -0.75em;
		font-size: 0.875em;
		top: 0.1em;
	}
}
////																							



//~~	bootstrap icons  ~~~~~~~~~~~~~~~~~~~~~~~~~//
.bi { width: 1rem; }
////																							



//~~	buttons  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
//		base styles
.btn {
	padding: calc(var(--#{$prefix}btn-padding-y) / 2) var(--#{$prefix}btn-padding-x) var(--#{$prefix}btn-padding-y);
	text-transform: lowercase;
}

.btn-icon {
	display: inline-flex;
	column-gap: 0.35rem;
	align-items: baseline;

	svg { width: 0.7em; }
}
////																							



//~~	hero	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
[data-template=home] #Hero {
	position: relative;

	.container-md { height: 100%; }
}

[data-template=resume] #Hero {
	h2 span:nth-child(1)::after {
		content: '•';
		position: relative;
		bottom: 0.25em;
		margin: 0 0.75em;
		font-size: 0.5em;
	}
}

.scroll-indicator {
	position: absolute;
	bottom: 1rem;
	right: 1rem;

	.arrow.down {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 2.75rem;
		transform: rotate(-14deg);

		path {
			stroke-dasharray: 1;
			stroke-dashoffset: 1;
			animation: scroll 8s linear 5s infinite;
		}
	}

	&:hover .arrow.down path {
		animation: scroll-quick 1s linear forwards;
	}
}
////																							



//~~	home	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
//		home
[data-template=home] {
	h1 {
		height: 100%;
		line-height: 0.9;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		font-size: 20vw;

		> .row {
			position: relative;
			align-content: center;
			padding-bottom: 2rem;

			&::before {
				content: "&";
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: -1;
				font-size: 70vw;
				font-family: $font-family-serif-decoration;
				mix-blend-mode: overlay;
				opacity: 0.2;
			}

			> .col { mix-blend-mode: overlay; }
			
			.col:nth-child(2n) { text-align: right; }
		}
	}

	.tagline { mix-blend-mode: overlay; }

	#about {
		scroll-margin-top: 6rem;

		.title p {
			font-size: 1.5rem;
		}
	}
}
////																							



//~~	resume	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
[data-template=resume] {
	.nav {
		position: sticky;
		top: 150px;
		z-index: 1;
	}

	h3 + div { margin-top: 1.5rem; }

	.resume-item { margin-bottom: 2.5rem; }

	.resume-item-header {
		img {
			height: 2rem;
			width: 2rem;
			padding: 5px;
			object-fit: contain;
			object-position: center center;
			border-radius: $border-radius-sm;
		}
		.chip { margin-top: -5px; }
		.bi { width: 0.875em; }
	}

	.position {
		.col-auto {
			width: 3rem;
			text-align: center;
			padding-top: 0.5rem;
			position: relative;
		}

		&:not(:last-child) > .col-auto::after {
			content: '';
			border-right: 1px solid $body-color;
			height: calc(100% - 24px);
			width: calc(49% + 1px);
			position: absolute;
			top: 2rem;
			left: 0;
		}
	}
	
	.logo {
		width: 2rem;
		height: 2rem;
		padding: 0.4rem;
		border-radius: $border-radius-sm;
	}

	.logo-altman-hall { padding: 0.25rem; }
	.logo-anna-baker-design { padding: 0.55rem; }
	.logo-mercyhurst { padding: 0.25rem; }
	.logo-vert-markets { padding: 0.25rem; }

	#industry-experience, #teaching-experience, #education, #skills {
		scroll-margin-top: 8rem;
	}
}
////																							


//~~	animations	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
//		scroll arrow
@keyframes scroll {
	0% {
		stroke-dashoffset: 1;
	}
	15%, 60% {
		stroke-dashoffset: 0;
	}
	75%, 100% {
		stroke-dashoffset: -1;
	}
}

@keyframes scroll-quick {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}
////																							



//~~	responsive	~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
// X-Small devices (portrait phones, less than 576px)
@media screen and (max-width: 575px) {}

// Small devices (landscape phones, 576px and up)
@media screen and (min-width: 576px) {
	[data-template=home] {
		h1 { padding-top: 100px; }
	}
}

// Medium devices (tablets, 768px and up)
@media screen and (min-width: 768px) {
	[data-template=home] {
		#Hero { height: 100vh; }

		h1 {
			padding-top: 0;
			font-size: 16vw;

			> .row {
				&::before {
					bottom: -10vh;
				}

				.col:nth-child(2n) { text-align: left; }
			}
		}
	}
	
	.scroll-indicator {
		bottom: 3.25rem;
		right: 3.25rem;

		.arrow.down {
			bottom: 0.25rem;
			right: 1.25rem;
		}
	}
}

// Large devices (desktops, 992px and up)
@media screen and (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media screen and (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media screen and (min-width: 1400px) {
	[data-template=home] {
		h1 {
			font-size: 12rem;

			> .row::before { font-size: 50rem; }
		}
	}
}
////																							
